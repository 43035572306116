import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ComeOn = () => (
    <StaticQuery
        query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "ComeOn.png" }) {
          childImageSharp {
            fixed(width: 125, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
        render={data => <Img alt="ComeOn" fixed={data.placeholderImage.childImageSharp.fixed} />}
    />
);

export default ComeOn
