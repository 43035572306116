import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import containerStyles from "./layout.module.scss";

import Header from "./header";
import "./layout.css";

const Submenu = ({ links }) => {
  return (
    <ul className={containerStyles.nav__submenu}>
      {links.map(link => (
        <li key={link.name}>
          <Link className={containerStyles.nav__submenuItem} to={link.link}>
            {link.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const Menu = ({ menuLinks }) => (
  <nav className={containerStyles.nav}>
    {menuLinks.map(link => (
      <li className={containerStyles.nav__menuItem} key={link.name}>
        <Link style={{ color: `white` }} to={link.link}>
          {link.name}
        </Link>
        {link.subLinks && <Submenu links={link.subLinks} />}
      </li>
    ))}
  </nav>
);

const Sidebar = () => (
  <div
    style={{
      background: "white",
      width: "300px"
    }}
  >
  </div>
);

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              subLinks {
                name
                link
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div
        style={{
          background: "lightgrey"
        }}
      >
        <div
          style={{ marginBottom: "1.45rem" }}
          className={containerStyles.size}
        >
          <Header
            menuLinks={data.site.siteMetadata.menuLinks}
            siteTitle={data.site.siteMetadata.title}
          />
          <Menu menuLinks={data.site.siteMetadata.menuLinks} />
          <div
            style={{
              display: "flex",
              maxWidth: 1200
              // justifyContent: "center"
            }}
          >
            <div
              style={{
                maxWidth: 560,
                padding: "0px 1.0875rem 1.45rem",
                paddingTop: 0
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
