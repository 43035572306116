import React from "react";

const SITES = {
  LEOVEGAS: {
    link: "http://ads.leovegas.com/redirect.aspx?pid=2483&bid=1511"
  },
  MRGREEN: {
    link: "http://ads.mrgreen.com/redirect.aspx?pid=3734"
  },
  CASUMO: {
    link: "http://ads.casumoaffiliates.com/redirect.aspx?pid=2218&bid=1546"
  },
  COMEON: {
    link: "http://ads.comeon.com/redirect.aspx?pid=3661&bid=7263"
  }
};

const AffiliateLink = ({ children, site }) => (
  <a
    href={site ? site.link : ""}
    rel="noopener noreferrer nofollow"
    target="_blank"
  >
    {children}
  </a>
);

export { AffiliateLink, SITES };
