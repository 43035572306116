import React from "react";
import LeoVegas from "./logos/leovegas";
import Casumo from "./logos/casumo";
import MrGreen from "./logos/mrgreen";
import ComeOn from "./logos/comeon";
import { AffiliateLink, SITES } from "./affiliate-link";

const Offer = ({ over, middle, under }) => (
  <td>
    <div style={{ fontSize: "12px" }}>{over}</div>
    <div style={{ fontSize: "24px", lineHeight: "20px" }}>{middle}</div>
    <div style={{ fontSize: "10px" }}>{under}</div>
  </td>
);

//TODO fix stars in raiting
const Raiting = () => (
  <td>
    <div className="rating"></div>
  </td>
);

const Visit = () => <div className="bonus">Besök</div>;

const StodLinjen = () => (
  <div>
    18+ |{" "}
    <a
      href="https://www.stodlinjen.se"
      onClick="http://www.stodlinjen.se"
      rel="noopener noreferrer nofollow"
      target="_blank"
    >
      Spela casino ansvarsfullt
    </a>
    | Regler &amp; villkor gäller
  </div>
);

const AffiliateLinkTD = ({ site, children }) => (
  <td>
    <AffiliateLink site={site}>{children}</AffiliateLink>
  </td>
);

const LeoVegasRow = () => (
  <tr>
    <AffiliateLinkTD site={SITES.LEOVEGAS}>
      <LeoVegas />
    </AffiliateLinkTD>
    <Raiting />
    <Offer over="200% upp till" middle="4000kr" under="+50 freespins" />
    <AffiliateLinkTD site={SITES.LEOVEGAS}>
      <Visit />
    </AffiliateLinkTD>
  </tr>
);

const MrGreenRow = () => (
  <tr>
    <AffiliateLinkTD site={SITES.MRGREEN}>
      <MrGreen />
    </AffiliateLinkTD>
    <Raiting />
    <Offer over="" middle="1000kr" under="+10 freespins" />
    <AffiliateLinkTD site={SITES.MRGREEN}>
      <Visit />
    </AffiliateLinkTD>
  </tr>
);

const CasumoRow = () => (
  <tr>
    <AffiliateLinkTD site={SITES.CASUMO}>
      <Casumo />
    </AffiliateLinkTD>
    <Raiting />
    <Offer over="" middle="€200" under="+20 freespins" />
    <AffiliateLinkTD site={SITES.CASUMO}>
      <Visit />
    </AffiliateLinkTD>
  </tr>
);

const LeoVegasTable = () => (
  <>
    <StodLinjen />
    <table>
      <tbody>
        <LeoVegasRow />
      </tbody>
    </table>
    <p style={{ fontStyle: "italic" }}>
      Erbjudandet gäller nya spelare från 20 maj 2019 och framåt. Min.
      insättning 100 kr. 15x omsättningskrav. Bonusen är giltig i 60 dagar. Cash
      Free Spins är omsättningsfria, ev. vinster är riktiga pengar
    </p>
  </>
);

const MrGreenTable = () => (
  <>
    <StodLinjen />
    <table>
      <tbody>
        <MrGreenRow />
      </tbody>
    </table>
  </>
);

const CasumoTable = () => (
  <>
    <StodLinjen />
    <table>
      <tbody>
        <CasumoRow />
      </tbody>
    </table>
  </>
);

const TopList = () => {
  return (
    <>
      <StodLinjen />
      <table>
        <tbody>
          <MrGreenRow />
          <CasumoRow />
          <tr>
            <AffiliateLinkTD site={SITES.COMEON}>
              <ComeOn />
            </AffiliateLinkTD>
            <Raiting />
            <Offer over="" middle="1200kr" under="+10 freespins" />
            <AffiliateLinkTD site={SITES.COMEON}>
              <Visit />
            </AffiliateLinkTD>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export { TopList, MrGreenTable, CasumoTable };
