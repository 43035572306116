// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-posts-free-spins-15-februari-2014-mdx": () => import("./../src/posts/free-spins-15-februari-2014.mdx" /* webpackChunkName: "component---src-posts-free-spins-15-februari-2014-mdx" */),
  "component---src-posts-free-spins-16-februari-2014-mdx": () => import("./../src/posts/free-spins-16-februari-2014.mdx" /* webpackChunkName: "component---src-posts-free-spins-16-februari-2014-mdx" */),
  "component---src-posts-free-spins-17-februari-2014-mdx": () => import("./../src/posts/free-spins-17-februari-2014.mdx" /* webpackChunkName: "component---src-posts-free-spins-17-februari-2014-mdx" */),
  "component---src-posts-free-spins-18-februari-2014-mdx": () => import("./../src/posts/free-spins-18-februari-2014.mdx" /* webpackChunkName: "component---src-posts-free-spins-18-februari-2014-mdx" */),
  "component---src-posts-free-spins-19-februari-2014-mdx": () => import("./../src/posts/free-spins-19-februari-2014.mdx" /* webpackChunkName: "component---src-posts-free-spins-19-februari-2014-mdx" */),
  "component---src-posts-free-spins-20-februari-2014-mdx": () => import("./../src/posts/free-spins-20-februari-2014.mdx" /* webpackChunkName: "component---src-posts-free-spins-20-februari-2014-mdx" */),
  "component---src-posts-free-spins-21-februari-2014-mdx": () => import("./../src/posts/free-spins-21-februari-2014.mdx" /* webpackChunkName: "component---src-posts-free-spins-21-februari-2014-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casino-automater-mdx": () => import("./../src/pages/casino-automater.mdx" /* webpackChunkName: "component---src-pages-casino-automater-mdx" */),
  "component---src-pages-casino-bonus-utan-insattning-mdx": () => import("./../src/pages/casino-bonus-utan-insattning.mdx" /* webpackChunkName: "component---src-pages-casino-bonus-utan-insattning-mdx" */),
  "component---src-pages-casumo-mdx": () => import("./../src/pages/casumo.mdx" /* webpackChunkName: "component---src-pages-casumo-mdx" */),
  "component---src-pages-elements-awakening-mdx": () => import("./../src/pages/elements-awakening.mdx" /* webpackChunkName: "component---src-pages-elements-awakening-mdx" */),
  "component---src-pages-gratis-free-spins-mdx": () => import("./../src/pages/gratis-free-spins.mdx" /* webpackChunkName: "component---src-pages-gratis-free-spins-mdx" */),
  "component---src-pages-hall-of-gods-mdx": () => import("./../src/pages/hall-of-gods.mdx" /* webpackChunkName: "component---src-pages-hall-of-gods-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-mega-fortune-mdx": () => import("./../src/pages/mega-fortune.mdx" /* webpackChunkName: "component---src-pages-mega-fortune-mdx" */),
  "component---src-pages-mrgreen-mdx": () => import("./../src/pages/mrgreen.mdx" /* webpackChunkName: "component---src-pages-mrgreen-mdx" */),
  "component---src-pages-samsung-galaxy-casino-mdx": () => import("./../src/pages/samsung-galaxy-casino.mdx" /* webpackChunkName: "component---src-pages-samsung-galaxy-casino-mdx" */),
  "component---src-pages-spela-roulette-online-mdx": () => import("./../src/pages/spela-roulette-online.mdx" /* webpackChunkName: "component---src-pages-spela-roulette-online-mdx" */),
  "component---src-pages-spela-spelautomater-mdx": () => import("./../src/pages/spela-spelautomater.mdx" /* webpackChunkName: "component---src-pages-spela-spelautomater-mdx" */),
  "component---src-pages-spelautomater-mdx": () => import("./../src/pages/spelautomater.mdx" /* webpackChunkName: "component---src-pages-spelautomater-mdx" */),
  "component---src-pages-thrills-casino-mdx": () => import("./../src/pages/thrills-casino.mdx" /* webpackChunkName: "component---src-pages-thrills-casino-mdx" */),
  "component---src-pages-vad-skulle-du-gora-om-du-vann-237-miljoner-mdx": () => import("./../src/pages/vad-skulle-du-gora-om-du-vann-237-miljoner.mdx" /* webpackChunkName: "component---src-pages-vad-skulle-du-gora-om-du-vann-237-miljoner-mdx" */),
  "component---src-pages-wild-rockets-mdx": () => import("./../src/pages/wild-rockets.mdx" /* webpackChunkName: "component---src-pages-wild-rockets-mdx" */)
}

